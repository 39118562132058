@import url('https://fonts.googleapis.com/css2?family=Antic+Slab&family=Orbitron:wght@400..900&display=swap');

/** Global variables */
:root {
  /*******************
   *  Colors
   *******************/
  --color-background: #000000;
  --color-primary: #206fff;

  --color-menu-button: #b3e5fc;
  --color-menu-button-hover: #e6ffff;
  --color-menu-button-active: #82b3c9;

  --color-text: #e6ffffdd;
  --color-header: #40afffdd;
  --color-dt: #e6ffff;

  --color-link: #81d4fadd;
  --color-link-hover: #b6ffffdd;
  --color-link-active: #4ba3c7dd;
}

/*******************
 *  Font Size
 *******************/
@media screen and (min-width: 551px) {
  :root {
    --line-height: 40px;
    --font-size: 20px;
    --font-size-menu: 24px;
    --font-size-h1: 48px;
    --font-size-h2: 36px;
    --font-size-h3: 28px;

    --margin-header-top: 60px;
    --margin-header-bottom: 20px;
  }
}
@media screen and (max-width: 550px) {
  :root {
    --line-height: 3.6364vw;
    --font-size: 3.6364vw;
    --font-size-menu: 4.3636vw;
    --font-size-h1: 8.7273vw;
    --font-size-h2: 6.5454vw;
    --font-size-h3: 5.0909vw;

    --margin-header-top: 10.9091vw;
    --margin-header-bottom: 3.6364vw;
  }
}

a,
a:visited {
  color: var(--color-link);
  text-decoration: none;
}

a:hover {
  color: var(--color-link-hover);
  text-decoration: none;
}

a:active {
  color: var(--color-link-active);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-header);
  font-weight: 400;
  font-family: 'Orbitron', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: var(--font-size);
  font-family: 'Antic Slab', serif;
  line-height: 2em;
}
