@media screen and (min-width: 551px) {
  .content {
    padding: 110px 30px 30px 30px;
  }
  dd {
    margin-left: 40px;
  }
}

@media screen and (max-width: 550px) {
  .content {
    padding: calc(18.18vw + 10px) 5.4545vw 5.4545vw 5.4545vw;
  }
  dd {
    margin-left: 7.2727vw;
  }
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h1,
h2,
h3,
h4 {
  margin: var(--margin-header-top) 0 var(--margin-header-bottom) 0;
}

p {
  margin: 0 0 2em 0;
}

dt {
  color: var(--color-dt);
  font-weight: bold;
}

@media screen and (max-width: 860px) {
  iframe.tutorial {
    display: block;
    border: none;
    margin: none;
    width: calc(65.17vw);
    height: calc(36.63vw);
  }
}

@media screen and (min-width: 861px) {
  iframe.tutorial {
    display: block;
    border: none;
    margin: 0;
    width: 560px;
    height: 315px;
  }
}
