@import url(https://fonts.googleapis.com/css2?family=Antic+Slab&family=Orbitron:wght@400..900&display=swap);
/** Global variables */
:root {
  /*******************
   *  Colors
   *******************/
  --color-background: #000000;
  --color-primary: #206fff;

  --color-menu-button: #b3e5fc;
  --color-menu-button-hover: #e6ffff;
  --color-menu-button-active: #82b3c9;

  --color-text: #e6ffffdd;
  --color-header: #40afffdd;
  --color-dt: #e6ffff;

  --color-link: #81d4fadd;
  --color-link-hover: #b6ffffdd;
  --color-link-active: #4ba3c7dd;
}

/*******************
 *  Font Size
 *******************/
@media screen and (min-width: 551px) {
  :root {
    --line-height: 40px;
    --font-size: 20px;
    --font-size-menu: 24px;
    --font-size-h1: 48px;
    --font-size-h2: 36px;
    --font-size-h3: 28px;

    --margin-header-top: 60px;
    --margin-header-bottom: 20px;
  }
}
@media screen and (max-width: 550px) {
  :root {
    --line-height: 3.6364vw;
    --font-size: 3.6364vw;
    --font-size-menu: 4.3636vw;
    --font-size-h1: 8.7273vw;
    --font-size-h2: 6.5454vw;
    --font-size-h3: 5.0909vw;

    --margin-header-top: 10.9091vw;
    --margin-header-bottom: 3.6364vw;
  }
}

a,
a:visited {
  color: #81d4fadd;
  color: var(--color-link);
  text-decoration: none;
}

a:hover {
  color: #b6ffffdd;
  color: var(--color-link-hover);
  text-decoration: none;
}

a:active {
  color: #4ba3c7dd;
  color: var(--color-link-active);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #40afffdd;
  color: var(--color-header);
  font-weight: 400;
  font-family: 'Orbitron', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  background-color: var(--color-background);
  color: #e6ffffdd;
  color: var(--color-text);
  font-size: var(--font-size);
  font-family: 'Antic Slab', serif;
  line-height: 2em;
}

@media screen and (min-width: 551px) {
  .content {
    padding: 110px 30px 30px 30px;
  }
  dd {
    margin-left: 40px;
  }
}

@media screen and (max-width: 550px) {
  .content {
    padding: calc(18.18vw + 10px) 5.4545vw 5.4545vw 5.4545vw;
  }
  dd {
    margin-left: 7.2727vw;
  }
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h1,
h2,
h3,
h4 {
  margin: var(--margin-header-top) 0 var(--margin-header-bottom) 0;
}

p {
  margin: 0 0 2em 0;
}

dt {
  color: var(--color-dt);
  font-weight: bold;
}

@media screen and (max-width: 860px) {
  iframe.tutorial {
    display: block;
    border: none;
    margin: none;
    width: calc(65.17vw);
    height: calc(36.63vw);
  }
}

@media screen and (min-width: 861px) {
  iframe.tutorial {
    display: block;
    border: none;
    margin: 0;
    width: 560px;
    height: 315px;
  }
}

.menu-button,
.menu-button:visited {
  color: var(--color-menu-button);
  font-family: 'Arial', sans-serif;
  font-size: var(--font-size-menu);
  margin: 15px;
}

.menu-button:hover {
  color: var(--color-menu-button-hover);
}

.menu-button:active {
  color: var(--color-menu-button-active);
}

@media screen and (max-width: 550px) {
  .menu-button {
    margin: 2.72vw;
  }
}

.app-bar {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  width: calc(97% - 5px);
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--color-background);
  padding: 5px;
  padding-right: 3%;
  align-items: center;
  z-index: 2;
  box-shadow: 0 0 30px 0 var(--color-primary);
}

@media screen and (max-width: 550px) {
  .logo {
    height: 18.18vw;
    margin-right: auto;
  }
}

@media screen and (min-width: 551px) {
  .logo {
    height: 100px;
    margin-right: auto;
  }
}

.spawn {
  color: #76ff03;
}

.spawn:hover {
  color: #ccff90;
}

.spawn:active {
  color: #64dd17;
}

.kill {
  color: #d50000;
}

.kill:hover {
  color: #ff8a80;
}

.kill:active {
  color: #b71c1c;
}

.start {
  color: #1de9b6;
}

.start:hover {
  color: #a7ffeb;
}

.start:active {
  color: #00bfa5;
}

.stop {
  color: #ff9100;
}

.stop:hover {
  color: #ffd180;
}

.stop:active {
  color: #ff6d00;
}

table.commands {
  border-spacing: 0;
}

tr.command {
  border: none;
  box-shadow: inset 0 0 10px 0 var(--color-primary);
}

@media screen and (max-width: 550px) {
  tr.command td {
    vertical-align: middle;
    padding: 3.6363vw;
    font-size: var(--font-size-h3);
  }

  td.command-name {
    width: 36.3636vw;
  }
}

@media screen and (min-width: 551px) {
  tr.command td {
    vertical-align: middle;
    padding: 20px;
    font-size: var(--font-size-h3);
  }

  td.command-name {
    width: 200px;
  }
}

tr.command td i {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  vertical-align: middle;
  margin-bottom: 5px;
  font-size: var(--font-size-h3);
}

.dynmap {
  position: fixed;
  width: 100%;
  border: none;
  margin: 110px 0 0 0;
  height: calc(100vh - 110px);
  padding: 0;
  background-color: #ffffff22;
}

@media screen and (max-width: 550px) {
  .dynmap {
    height: calc(100vh - 20vw);
    margin: 20vw 0 0 0;
  }
}

