.app-bar {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  width: calc(97% - 5px);
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--color-background);
  padding: 5px;
  padding-right: 3%;
  align-items: center;
  z-index: 2;
  box-shadow: 0 0 30px 0 var(--color-primary);
}

@media screen and (max-width: 550px) {
  .logo {
    height: 18.18vw;
    margin-right: auto;
  }
}

@media screen and (min-width: 551px) {
  .logo {
    height: 100px;
    margin-right: auto;
  }
}
